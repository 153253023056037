import { createRouter, createWebHistory } from 'vue-router';

import Home from './components/MainPage/MainPage.vue';
import Catalog from './components/Catalog/Catalog.vue';
import Cart from './components/Cart/Cart.vue';
import Profile from './components/Profile/Profile.vue';
import Purchases from './components/Profile/Purchases.vue';
import Favorites from './components/Profile/Favorites.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/catalog', component: Catalog },
  { path: '/cart', component: Cart },
  { path: '/profile', component: Profile },
  { path: '/profile/purchases', component: Purchases },
  { path: '/profile/favorites', component: Favorites },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;