<script setup>
import Navigation from './components/Navigation.vue';
</script>

<template>
  <Navigation />
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('user', ['getId']),
  },
  async mounted() {
    const telegram = window.Telegram.WebApp;

    await this.getAccount({ user: telegram.initDataUnsafe.user, initData: telegram.initData });

    await this.getStories();
    await this.getCart(telegram.initDataUnsafe.user.id);
    await this.getCategories();
    await this.getProducts({ limit: 10, offset: 0 });

    telegram.expand();
    telegram.ready();
    
    telegram.onEvent('backButtonClicked', async () => {
        await this.saveCart(this.getId);
        telegram.close();
        window.close();
    });

    telegram.BackButton.show();
  },
  methods: {
    ...mapActions('user', ['getAccount']),
    ...mapActions('stories', ['getStories']),
    ...mapActions('products', ['getProducts']),
    ...mapActions('categories', ['getCategories']),
    ...mapActions('cart', ['getCart', 'saveCart']),
  },
};
</script>


<style>
:root {
  user-select: none;
  cursor: default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1A1A1A;
  --animate-duration: 500ms;
  --animation-delay: 0ms;
}
:root {
  font-size: calc(15px + (34 - 15) * ((100vw - 360px) / (810 - 360)));
}

@media (max-width: 360px) {
  :root {
    font-size: 15px;
  }
}

@media (min-width: 810px) {
  :root {
    font-size: 34px;
  }
}
</style>