<template>
  <div class="profile-page">
    <div class="profile">
      <div class="info">
          <div class="photo">
            <img :src="photo" alt="">
          </div>
          <div class="name">
            <a>{{ name }}</a>
            <br>
            <a id="user-tag">@{{ username }}</a>
          </div>
      </div>
      <div class="balance">$ {{ balance }}</div>
      <button class="funds">Пополнить счет</button>
    </div>
    <div class="profile-buttons">
      <router-view v-slot="{ Component }">
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
          mode="out-in"
        >
          <component :is="Component" />
        </transition>
      </router-view>
      <router-link to="/profile/purchases">
        <div class="button">
          <img src="../../assets/icons/shopping.svg" alt="">
          <p>Покупки</p>
        </div>
      </router-link>
      <router-link to="/profile/favorites">
        <div class="button">
          <img src="../../assets/icons/favorites.svg" alt="">
          <p>Избранное</p>
        </div>
      </router-link>
      <div class="button">
        <img src="../../assets/icons/invite.svg" alt="">
        <p>Пригласить друга</p>
      </div>
      <div class="button">
        <img src="../../assets/icons/recovery.svg" alt="">
        <p>Код восстановления</p>
      </div>
      <div class="button">
        <img src="../../assets/icons/support.svg" alt="">
        <p>Поддержка</p>
      </div>
      <div class="button">
        <img src="../../assets/icons/faq.svg" alt="">
        <p>FAQ</p>
      </div>
      <div class="button">
        <img src="../../assets/icons/translate.svg" alt="">
        <p>Смена языка</p>
      </div>
      <div id="become-seller" class="button">
        <img src="../../assets/icons/shopping_2.svg" alt="">
        <img id="become-seller-background" src="../../assets/icons/shopping_3.svg" alt="">
        <p>Стать продавцом</p>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('user', ['name', 'username', 'photo', 'balance']),
  }
};
</script>


<style scoped>
.profile-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-bottom: 3.75rem;
}
.profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15.25rem;
  box-sizing: border-box;
  background-color: #2876B5;
  border-radius: 2rem;
  padding: 1.5rem;
  background-image: url(../../assets/images/lion.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.info {
  display: flex;
  align-items: center;
}
.info img {
  border-radius: 50%;
  margin-right: 0.625rem;
  width: 3.125rem;
  height: 3.125rem;
}
.name a {
  font-family: "SF-Pro-Display-semibold";
  color: #fff;
  font-size: 1rem;
  line-height: 0.375rem;
}
#user-tag {
  color: rgba(255, 255, 255, 0.5);
  font-family: "SF-Pro-Display-regular";
  font-size: 0.875rem;
}
.balance {
  color: white;
  margin: 2.125rem 0;
  font-family: "SF-Pro-Display-semibold";
  font-size: 2rem;
}
.funds {
  font-family: "SF-Pro-Display-semibold";
  background-color: white;
  color: black;
  font-size: 1rem;
  padding: 0.625rem 1.25rem;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 3.4375rem;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  transition: all .2s;
}
.funds:active {
  transform: scale(0.985);
}
.profile-buttons {
  width: 100%;
  margin-top: 1.25rem;
}
a {
  text-decoration: none;
}
.button {
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.0192) 100%);
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 1.5rem;
  height: 4rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  transition: all .2s;
}
.button:active {
  transform: scale(0.985);
}
.button img {
  margin-right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}
.button p {
  font-family: "SF-Pro-Display-medium";
  color: #fff;
  font-size: 1rem;
  line-height: 0.375rem;
}
#become-seller {
  margin-top: 1.25rem;
  position: relative;
  background: linear-gradient(90deg, #276CA3 0%, #3CA4F8 100%);
  border-radius: 1rem;
  overflow: hidden;
}
#become-seller-background {
  position: absolute;
  left: 8rem;
  margin: 0;
  width: 16.375rem;
  height: 7.0625rem;
}
</style>