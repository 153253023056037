<template>
  <router-view v-slot="{ Component }">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <component :is="Component" />
    </transition>
  </router-view>
  <nav class="navbar">
    <router-link to="/" class="nav-item">
      <img src="../assets/icons/home.svg" alt="home">
    </router-link>
    <router-link to="/catalog" class="nav-item">
      <img src="../assets/icons/catalog.svg" alt="catalog">
    </router-link>
    <router-link to="/cart" class="nav-item">
      <img src="../assets/icons/cart.svg" alt="cart">
      <div v-if="this.indicator" class="cart-indicator">{{ localQuantity }}</div>
    </router-link>
    <router-link to="/profile" class="nav-item">
      <img src="../assets/icons/profile.svg" alt="profile">
    </router-link>
  </nav>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      indicator: true,
      localQuantity: 0,
    };
  },
  computed: {
    ...mapGetters('cart', ['getCart']),

    quantity() {
      return Object.keys(this.getCart).length;
    },
  },
  watch: {
    quantity: {
      immediate: true,
      handler(newCount) {
        if (newCount > 99) {
          this.localQuantity = '99+';
        }
        else if (newCount > 0) {
          this.indicator = true;
          this.localQuantity = newCount;
        } else {
          this.indicator = false;
          this.localQuantity = 0;
        }
      }
    }
  },
};
</script>


<style scoped>
.navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.625rem 0;
  left: 0;
  background: #1A1A1A;
}
.nav-item {
  text-decoration: none;
  fill: #333;
  font-size: 1.5rem;
  position: relative;
}
.nav-item.router-link-exact-active img {
  fill: #fff;
  opacity: 1;
}
img {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.625rem;
  opacity: 0.2;
}
.cart-indicator {
  display: flex;
  position: absolute;
  top: 0.5rem;
  right: 0;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  color: #fff;
  background: #D91E1E;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'SF-Pro-Display-semibold';
  font-size: 0.5rem;
}
.fade-enter-from,
.fade-leave-tp {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}
</style>