<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
</script>


<template>
  <div class="carousel">
    <swiper
      :slides-per-view="3.5"
      :space-between="12"
      class="swiper"
    >
      <swiper-slide v-for="story in getStories" :key="story.id">
        <img :src="story.photo" alt="" @click="gotoStory(story.url)"/>
      </swiper-slide>
    </swiper>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('stories', ['getStories']),
  },
  methods: {
    gotoStory(url) {
      window.Telegram.WebApp.openLink(url, { try_instant_view: true });
    },
  },
}
</script>


<style scoped>
.carousel {
  width: 100%;
  margin-top: 1.5rem;
}
.carousel img {
  width: 6.0625rem;
  height: 6.1875rem;
  border-radius: 26%;
  border: 1px solid #3CA2F5; /*rgba(255, 255, 255, 0.2);*/
  padding: 3px;
  box-sizing: border-box;
  transition: all .2s;
  cursor: pointer;
}
.carousel img:active {
  transform: scale(0.985);
}
.swiper {
  width: 100%;
}
</style>