import axios from "axios";

const state = {
  stories: [],
}

const mutations = {
  setStories(state, stories) {
    state.stories = stories;
  },
}

const actions = {
  async getStories({ commit }) {
    try {
      const res = await axios.get('/getStories');
      commit('setStories', res.data);
    } catch (error) {
      console.error('[getStories]: ', error);
    }
  },
};

const getters = {
  getStories: (state) => state.stories,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};