<template>  
  <div class="product">
    <div class="banner" :style="{ backgroundImage: `url(products/${photo})` }"></div>
    <div class="product-info">
      <p class="cost">$ {{ cost }}</p>
      <p class="name">{{ name }}</p>
      <p class="stock">В наличии: <span>{{ stock }}</span></p>
      <div v-if="!this.showQuantity" class="incart" @click="this.inCart">В корзину</div>
      <div v-if="this.showQuantity" class="quantity">
        <img id="minus" @click="this.minus" src="../../assets/icons/minus.svg" alt="">
        <img id="plus" @click="this.plus" src="../../assets/icons/plus.svg" alt="">
        {{ this.quantity }}
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      showQuantity: false,
      quantity: 0,
    };
  },
  props: {
    id: Number,
    cost: Number,
    name: String,
    stock: Number,
    photo: String,
  },
  mounted() {
    const cart = this.getCart;
    Object.values(cart).forEach(e => {
      if (e.id == this.id) {
        this.showQuantity = true;
        this.quantity = e.quantity;
      }
    });
  },
  computed: {
    ...mapGetters('cart', ['getCart']),
  },
  methods: {
    ...mapActions('cart', ['addToCart', 'removeFromCart', 'saveCart']),

    async inCart() {
      await this.plus();
      this.showQuantity = true;
    },
    async plus() {
      this.addToCart({ id: this.id, name: this.name, cost: this.cost, photo: this.photo, stock: this.stock });
      this.quantity++;
    },
    async minus() {
      this.removeFromCart(this.id);
      if (this.quantity == 1) {
        this.showQuantity = false;
      }
      this.quantity--;
    },
  },
}
</script>


<style scoped>
.product {
  box-sizing: border-box;
  width: 10.75rem;
  margin-bottom: 2rem;
}
.product .banner {
  width: 100%;
  height: 8.25rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 1.5rem;
}
.product-info {
  margin-left: 0.25rem;
}
.product .product-info .cost {
  font-family: "SF-Pro-Display-bold";
  color: #fff;
  font-size: 1.125rem;
  width: 100%;
}
.product .product-info .name {
  font-family: "SF-Pro-Display-regular";
  color: #fff;
  font-size: 0.875rem;
  width: 100%;
  height: 2rem;
}
.product .product-info .stock {
  font-family: "SF-Pro-Display-regular";
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.75rem;
  width: 100%;
}
.product .product-info .stock span {
  color: #fff;
}
.product .product-info .incart {
  font-family: "SF-Pro-Display-semibold";
  color: #fff;
  font-size: 0.875rem;
  text-align: center;
  background: linear-gradient(90deg, #276CA3 0%, #3CA4F8 100%);
  border-radius: 1rem;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  transition: all .2s;
}
.product .product-info .incart:active {
  transform: scale(0.98);
}
.product .product-info .quantity {
  position: relative;
  width: 7.8125rem;
  font-family: "SF-Pro-Display-semibold";
  color: #fff;
  font-size: 0.875rem;
  text-align: center;
  background: linear-gradient(91.92deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.12) 50%, rgba(255, 255, 255, 0.04) 100%);
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 0.75rem;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  transition: all .2s;
}
#minus {
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: 0;
  margin-left: 0.75rem;
}
#plus {
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 0;
  margin-right: 0.53125rem;
}
</style>