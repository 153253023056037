<script setup>
import Search from '../Search.vue';
import Stories from './Stories.vue';
import Product from './Product.vue';
</script>


<template>
  <div class="main-page">
    <div class="profile">
      <div class="info">
        <div class="photo">
          <img :src="photo" alt="">
        </div>
        <div class="name">
          <a>{{ name }}</a>
          <br>
          <a id="user-tag">@{{ username }}</a>
        </div>
      </div>
      <div class="balance">$ {{ balance }}</div>
    </div>
    <Search />
    <Stories />
    <div class="products">
      <p>Список товаров</p>
      <div v-for="product in getProducts" :key="product.id">
        <Product :id="product.id" :cost="product.cost" :name="product.name" :stock="product.stock" :photo="product.photo" />
      </div>
      <!--<div v-for="i in 100" :key="i">
        <Product :id="i" :cost="100" :name="'Продукт ' + i" :stock="10" />
      </div>!-->
    </div>
  </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState('user', ['name', 'username', 'photo', 'balance']),
    ...mapGetters('products', ['getProducts']),
  },
};
</script>


<style scoped>
@font-face {
    font-family: "SF-Pro-Display-semibold";
    src: url('../../assets/fonts/SF-Pro-Display-Semibold.otf') format('truetype');
}
@font-face {
    font-family: "SF-Pro-Display-regular";
    src: url('../../assets/fonts/SF-Pro-Display-Regular.otf') format('truetype');
}
@font-face {
    font-family: "SF-Pro-Display-light";
    src: url('../../assets/fonts/SF-Pro-Display-Light.otf') format('truetype');
}
@font-face {
    font-family: "SF-Pro-Display-medium";
    src: url('../../assets/fonts/SF-Pro-Display-Medium.otf') format('truetype');
}
@font-face {
    font-family: "SF-Pro-Display-bold";
    src: url('../../assets/fonts/SF-Pro-Display-Bold.otf') format('truetype');
}
.main-page {
  padding: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.profile {
  justify-content: space-between;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  line-height: 0;
  align-items: center;
}
.info {
  display: flex;
  align-items: center;
}
.info img {
  display: flex;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}
.name a {
  font-family: "SF-Pro-Display-semibold";
  color: #fff;
  margin-left: 10px;
  font-size: 1rem;
  line-height: 1.2rem;
}
#user-tag {
  font-family: "SF-Pro-Display-regular";
  color: #666666;
  margin-left: 10px;
  font-size: 0.875rem;
}
.balance {
  font-family: "SF-Pro-Display-medium";
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  border-radius: 1.5rem;
  border: 1px solid #338bd2;
  background: linear-gradient(92deg, #276ca3 0%, #3ca4f8 100%);
  color: #fff;
  height: 2.5rem;
  padding: 0 1.5rem;
  font-size: 1rem;
}
.products {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 3.75rem;
}
.products p {
  font-family: "SF-Pro-Display-semibold";
  color: #fff;
  font-size: 1.5rem;
  width: 100%;
}
</style>