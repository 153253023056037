import axios from "axios";

const state = {
  products: [],
}

const mutations = {
  setProducts(state, products) {
    state.products = products;
  },
  addProduct(state, product) {
    state.products.push(product);
  },
};

const actions = {
  async getProducts({ commit }, { limit, offset }) {
    try {
      const res = await axios.post('/getProducts', { limit, offset });
      commit('setProducts', res.data);
    } catch (error) {
      console.error('[getProcuts]: ', error);
    }
  },
  async getProductById({ commit, dispatch }, { productId, quantity }) {
    try {
      const res = await axios.post('/getProductById', { id: productId });
      const product = res.data;
      product.quantity = Number(quantity);
      await dispatch('cart/addToCart', product, { root: true });
    }
    catch (error) {
      console.error('[getProductById]: ', error);
    }
  },
};

const getters = {
  getProducts: (state) => state.products,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};