import axios from "axios";

const state = {
  cart: {},
}

const mutations = {
  setCart(state, cart) {
    state.cart = cart;
  },
  addToCart(state, product) {
    if (state.cart[product.id]) {
      state.cart[product.id].quantity += 1;
    } else if (product.quantity > 0) {
      state.cart[product.id] = { ...product };
    } else {
      state.cart[product.id] = { ...product, quantity: 1 };
    }
  },
  removeFromCart(state, id) {
    if (state.cart[id]) {
      if (state.cart[id].quantity > 1) {
        state.cart[id].quantity -= 1;
      } else {
        delete state.cart[id];
      }
    }
  },
  clearCart(state) {
    state.cart = {};
  },
  getCart(state) {
    return state.cart;
  },
}

const actions = {
  addToCart({ commit }, product) {
    commit('addToCart', product);
  },
  removeFromCart({ commit }, product) {
    commit('removeFromCart', product);
  },
  clearCart({ commit }) {
    commit('clearCart');
  },
  async getCart({ commit, dispatch }, id) {
    try {
      const res = await axios.get(`/getCart?userId=${id}`);
      for (const productId in res.data) {
        const quantity = res.data[productId].quantity;
        await dispatch('products/getProductById', { productId, quantity }, { root: true });
      }
    } catch (error) {
      console.error('[getCart]: ', error);
    }
  },
  async saveCart({ state }, id) {
    try {
      const productIds = Object.keys(state.cart).join(',');
      const quantities = Object.values(state.cart).map(item => item.quantity).join(',');
      console.log('productIds: ', productIds);
      console.log('quantities: ', quantities);
      await axios.post(`/saveCart/${id}`, { productIds, quantities });
    } catch (error) {
      console.error('[saveCart]: ', error);
    }
  },
}

const getters = {
  getCart: (state) => state.cart,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}