import axios from "axios";

const state = {
  categories: [],
}

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
};

const actions = {
  async getCategories({ commit }) {
    try {
      const res = await axios.get('/getCategories');
      commit('setCategories', res.data);
    } catch (error) {
      console.error('[getCategories]: ', error);
    }
  },
};


const getters = {
  getCategories: (state) => state.categories,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};