<template>
  <div class="purchases-page">
    <div class="header">
        <img src="../../assets/icons/arrow-left.svg" alt="" class="goback" @click="$router.go(-1)">
        <p>Покупки</p>
    </div>
  </div>
</template>


<style scoped>
.purchases-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-bottom: 3.75rem;
}
.header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 3.25rem;
  position: relative;
}
.header .goback {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  left: 0;
  margin-left: 1rem;
  cursor: pointer;
}
.header p {
  font-family: "SF-Pro-Display-semibold";
  color: #fff;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}
</style>