<script setup>
import Search from '../Search.vue';
import Category from './Category.vue';
</script>


<template>
  <div class="catalog">
     <Search />
     <div class="categories">
      <div v-for="category in getCategories" :key="category.id">
        <Category :name="category.name" />
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('categories', ['getCategories']),
  },
}
</script>


<style scoped>
.catalog {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}
.categories {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(6rem, 1fr));
  gap: 0.75rem;
  width: 100%;
  height: 100%;
  margin-top: 1.5rem;
  box-sizing: border-box;
  padding-bottom: 4.5rem;
}
</style>