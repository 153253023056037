<template>
  <div class="search">
    <input type="text" placeholder="Поиск товара">
  </div>
</template>


<style scoped>
.search {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0);
  margin-top: 1.5rem;
  opacity: 0.3;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
  height: 3.5rem;
  width: 100%;
}
.search input {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1rem;
  padding-left: 3.25rem;
  font-family: "SF-Pro-Display-regular";
  font-weight: 400;
  right: 0;
  background-image: url(../assets/icons/search.svg);
  background-repeat: no-repeat;
  background-position: 1rem center;
  background-size: 1.25rem;
  height: 1.25rem;
  color: #FFFFFF;
}
</style>