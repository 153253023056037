<template>
  <div class="category">
      <p> {{ name }} </p>
  </div>
</template>


<script>
export default {
  props: {
    name: String,
  },
}
</script>


<style scoped>
.category {
  width: 7rem;
  height: 7rem;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 1.25rem;
  cursor: pointer;
  transition: all .2s;
}
.category:active {
  transform: scale(0.975);
}
.category p {
  font-family: "SF-Pro-Display-semibold";
  color: #fff;
  font-size: 0.875rem;
  padding: 0 0.75rem;
}
</style>