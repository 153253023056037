<template>
  <div class="product">
    <div class="product-info">
      <div class="banner" :style="{ backgroundImage: `url(products/${photo})` }"></div>
      <div class="content">
        <a id="cost">${{ cost }}</a>
        <a id="name">{{ name }}</a>
        <a id="stock">В наличии: <span>{{ stock }}</span></a>
      </div>
      <input type="checkbox" v-model="checked">
    </div>
    <div class="panel">
      <div class="buttons">
        <img src="../../assets/icons/favorites.svg" alt="">
        <img src="../../assets/icons/trash.svg" alt="">
      </div>
      <div class="quantity">
        <img id="minus" @click="this.minus" src="../../assets/icons/minus.svg" alt="">
        <img id="plus" @click="this.plus" src="../../assets/icons/plus.svg" alt="">
        {{ this.localQuantity }}
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      localQuantity: this.quantity,
      checked: false,
    }
  },
  props: {
    id: Number,
    photo: String,
    cost: Number,
    name: String,
    stock: Number,
    quantity: Number,
  },
  watch: {
    checked(newValue) {
      this.$emit('updateChecked', { id: this.id, checked: newValue });
    }
  },
  methods: {
    ...mapActions('cart', ['addToCart', 'removeFromCart', 'saveCart']),

    async plus() {
      this.addToCart({ id: this.id, name: this.name, cost: this.cost, photo: this.photo, stock: this.stock });
      this.localQuantity++;
    },
    async minus() {
      this.removeFromCart(this.id);
      if (this.localQuantity > 1) {
        this.localQuantity--;
      }
    },
  },
}
</script>


<style scoped>
.product {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: linear-gradient(100.6deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.0192) 100%);
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 1.5rem;
  margin-top: 1rem;
}
.product .product-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1rem;
  text-align: left;
}
.product .product-info .banner {
  width: 5.875rem;
  height: 5.875rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 1rem;
}
.product .product-info .content {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.product .product-info .content a {
  font-family: 'SF-Pro-Display-bold';
  color: #fff;
  font-size: 1.125rem;
}
#cost {
  margin-bottom: 0.84375rem;
  height: 1.3125rem;
}
#name {
  font-family: 'SF-Pro-Display-regular';
  color: #fff;
  font-size: 0.875rem;
  height: 2.125rem;
  margin-bottom: 0.5rem;
}
#stock {
  font-family: 'SF-Pro-Display-regular';
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.75rem;
}
#stock span {
  color: #fff;
}
input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.375rem;
  box-sizing: border-box;
  margin-right: 0.5rem;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
}
input[type="checkbox"]::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 0.25rem;
}
input[type="checkbox"]:checked::before {
  background-color: #3CA4F8;
}
.panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1rem;
  box-sizing: border-box;
}
.panel .buttons img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}
.panel .quantity {
  position: relative;
  width: 7.8125rem;
  font-family: "SF-Pro-Display-semibold";
  color: #fff;
  font-size: 0.875rem;
  text-align: center;
  background: linear-gradient(91.92deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.12) 50%, rgba(255, 255, 255, 0.04) 100%);
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 0.75rem;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  transition: all .2s;
}
#minus {
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: 0;
  margin-left: 0.75rem;
}
#plus {
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 0;
  margin-right: 0.53125rem;
}
</style>