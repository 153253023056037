<script setup>
import Product from './Product.vue';
</script>


<template>
  <div class="cart">
    <div class="panel">
      <p>Корзина</p>
      <div class="panel-buttons">
        <div class="left">
          <input type="checkbox" id="panel-checkbox">
          <p>Выбрать все</p>
        </div>
        <div class="right" @click="clear">
          <img src="../../assets/icons/close.svg" alt="">
          <p>Очистить</p>
        </div>
      </div>
      <div class="delimeter"></div>
      <div class="products">
        <div v-for="product in getCart" :key="product.id">
          <Product :id="product.id" :cost="product.cost" :name="product.name" :stock="product.stock" :photo="product.photo" :quantity="product.quantity" @updateChecked="updateChecked" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      checkedProducts: {},
    }
  },
  computed: {
    ...mapGetters('cart', ['getCart']),
    ...mapGetters('user', ['getId']),
  },
  methods: {
    ...mapActions('cart', ['clearCart', 'removeFromCart', 'saveCart']),

    updateChecked({ id, checked }) {
      this.checkedProducts[id] = checked;
    },
    async clear() {
      Object.keys(this.checkedProducts).forEach(id => {
        if (this.checkedProducts[id])
          this.removeFromCart(id);
      });
      await this.saveCart(this.getId);
    }
  },
}
</script>


<style scoped>
.cart {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 4.5rem;
}
.panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}
.panel p {
  font-size: 1.5rem;
  font-family: 'SF-Pro-Display-semibold';
  color: #fff;
}
.panel-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
}
.left, .right {
  display: flex;
  align-items: center;
}
.left {
  justify-content: flex-start;
  margin-left: -0.25rem;
  cursor: pointer;
}
.right {
  justify-content: flex-end;
  cursor: pointer;
}
.left input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.375rem;
  box-sizing: border-box;
  margin-right: 0.5rem;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
}
.left input[type="checkbox"]::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 0.25rem;
}
.left input[type="checkbox"]:checked::before {
  background-color: #3CA4F8;
}
.right img {
  margin-right: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  opacity: 0.5;
}
.panel-buttons p {
  font-size: 0.875rem;
  opacity: 0.5;
}
.delimeter {
  background: #292929;
  height: 1px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>