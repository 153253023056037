import axios from "axios";

const state = {
  id: 0,
  name: '',
  username: '',
  photo: '',
  balance: 0,
};

const mutations = {
  setId(state, id) {
    state.id = id;
  },
  setName(state, name) {
    state.name = name;
  },
  setUsername(state, username) {
    state.username = username;
  },
  setPhoto(state, photo) {
    state.photo = photo;
  },
  setBalance(state, balance) {
    if (typeof balance === 'number')
      state.balance = balance;
  },
};

const actions = {
  updateBalance({ commit }, balance) {
    commit('setBalance', balance);
  },
  async validateData({ commit }, initData) {
    try {
      await axios.post('/validateData', { initData });
    } catch (error) {
      console.error(error);
      //window.close();
    }
  },
  async getUserPhoto({ commit, state }) {
    try {
      const userId = state.id;
      const res = await axios.get(`/getUserPhoto?userId=${userId}`);
      commit('setPhoto', res.data.photoUrl);
    } catch (error) {
      commit('setPhoto', "https://api.dicebear.com/6.x/initials/svg?seed=" + state.user.first_name);
    }
  },
  async auth({ commit, state }) {
    try {
      const userId = state.id;
      const res = await axios.post('/auth', { userId });
      commit('setBalance', res.data.balance);
    } catch (error) {
      alert(error);
    }
  },
  async getAccount({ dispatch, commit }, { user, initData }) {
    commit('setId', user.id);
    commit('setName', user.first_name);
    commit('setUsername', user.username);
    
    await dispatch('validateData', initData);
    await dispatch('getUserPhoto');
    await dispatch('auth');
  },
};

const getters = {
  getId: (state) => state.id,
  getName: (state) => state.name,
  getUsername: (state) => state.username,
  getPhoto: (state) => state.photo,
  getBalance: (state) => state.balance,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};